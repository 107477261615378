document.addEventListener('DOMContentLoaded', function() {
  const header = document.getElementById('header');

  header.addEventListener('mouseover', function(event) {
    const dropdown = event.target.closest('.js-dropdown');
    if (dropdown) {
      const child = dropdown.querySelector('.js-dropdown-child');
      if (child) {
        child.classList.remove('opacity-0', '-mt-5', 'pointer-events-none');
      }
    }
  });

  header.addEventListener('mouseout', function(event) {
    const dropdown = event.target.closest('.js-dropdown');
    if (dropdown) {
      const child = dropdown.querySelector('.js-dropdown-child');
      if (child) {
        child.classList.add('opacity-0', '-mt-5', 'pointer-events-none');
      }
    }
  });
});
